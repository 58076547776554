import Telegram from "../../icons/Telegram";
import Twitter from "../../icons/Twitter";
import TelegramChina from "../../icons/TelegramChina";
import "./SocialMedia.css";

const socialLinks = [
    /** TODO временно убрали ссылки ТГ */
    // {
    //     href: "https://t.me/litera_reader_official",
    //     className: "social-telegram flex items-center gap-2",
    //     title: "Litera Reader on Telegram",
    //     Icon: Telegram,
    //     text: "Official channel",
    // },
    // {
    //     href: "https://t.me/litera_reader_cn",
    //     className: "telegram-china",
    //     title: "Chinese channel on Telegram",
    //     Icon: TelegramChina,
    // },
    {
        href: "https://x.com/Litera_Reader",
        className: "social-twitter",
        title: "Litera Reader on X",
        Icon: Twitter,
    },
];

const SocialMedia = () => {
    return (
        <div className="flex gap-3">
            {socialLinks.map((link, index) => (
                <a
                    key={index}
                    href={link.href}
                    className={link.className}
                    title={link.title}
                    target="_blank"
                    rel="noopener noreferrer"
                >
                    <link.Icon />
                    {link.text && <span>{link.text}</span>}
                </a>
            ))}
        </div>
    );
};

export default SocialMedia;
